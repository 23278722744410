import styled from "styled-components";
import team1 from "../../assets/images/team1.jpg";

export const PageWrapper = styled.div`
  width: 100%;
  font-family: Arial, sans-serif;
`;

export const Header = styled.header`
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(${team1});
  background-size: cover;
  background-position: center;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  & h1 {
    line-height: 9;
    color: #fff;
    font-weight: lighter;
  }
`;

export const Section = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  background-color: ${(props) => props.bgColor || "#fff"};
`;

export const SectionHeader = styled.h2`
  font-size: 2em;
  color: #333;
  margin-bottom: 1rem;
  text-align: left;
  padding-left: 1rem;
  border-left: 5px solid #eab42d;
`;

export const TeamWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(250px, 1fr)
  ); /* Increased min-width for larger cards */
  gap: 1rem; /* Reduced gap for smaller spacing in management section */
  justify-items: left;

  &.management {
    gap: 1rem; /* Specific gap for management section */
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: 300px;
  text-align: left;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  img {
    align-self: left;
    height: 300px;
    width: 300px;
    object-fit: cover;
    margin-bottom: 15px;
  }

  h3 {
    margin: 10px 0 5px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }

  p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #666;
  }
`;

export const IconText = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 5px 0;

  svg {
    width: 20px;
    height: 20px;
    color: #0073e6;
  }

  p {
    margin: 0;
  }
`;
