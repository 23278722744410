import React from "react";
import { Header, Container, List } from "./termsElements";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MotionDiv = ({ children, className, initial, animate, transition }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start(animate);
    }
  }, [controls, animate, inView]);

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={controls}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const Terms = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{t("terms:page.header")}</h1>
      </Header>
      <Container
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h2>{t("terms:page.header2")}</h2>
        <h2>{t("terms:page.header3")}</h2>
        <br></br>
        <p>{t("terms:page.paragraf")}</p>
        <hr></hr>
        <List>
          <ol>
            <b>{t("terms:page.headerTerm")}</b>
            <li>{t("terms:page.term")}</li>
            <li>{t("terms:page.term1")}</li>
            <li>{t("terms:page.term2")}</li>
            <li>{t("terms:page.term3")}</li>
            <li>{t("terms:page.term4")}</li>
            <li>{t("terms:page.term5")}</li>
            <li>{t("terms:page.term6")}</li>
            <li>{t("terms:page.term7")}</li>
            <li>{t("terms:page.term8")}</li>
            <li>{t("terms:page.term9")}</li>
            <li>{t("terms:page.term10")}</li>
            <li>{t("terms:page.term11")}</li>
            <li>{t("terms:page.term12")}</li>
            <li>{t("terms:page.term13")}</li>
            <li>{t("terms:page.term14")}</li>
            <li>{t("terms:page.term15")}</li>
            <li>{t("terms:page.term16")}</li>
            <li>{t("terms:page.term17")}</li>
            <li>{t("terms:page.term18")}</li>
            <li>{t("terms:page.term19")}</li>
            <li>{t("terms:page.term20")}</li>
            <li>{t("terms:page.term21")}</li>
            <li>{t("terms:page.term22")}</li>
            <li>{t("terms:page.term23")}</li>
            <li>{t("terms:page.term24")}</li>
            <li>{t("terms:page.term25")}</li>
            <li>{t("terms:page.term26")}</li>
            <li>{t("terms:page.term27")}</li>
            <li>{t("terms:page.term28")}</li>
            <li>{t("terms:page.term29")}</li>
            <li>{t("terms:page.term30")}</li>
            <li>{t("terms:page.term31")}</li>
            <li>{t("terms:page.term32")}</li>
            <li>{t("terms:page.term33")}</li>
            <li>{t("terms:page.term34")}</li>
            <li>{t("terms:page.term35")}</li>
            <li>{t("terms:page.term36")}</li>
            <li>{t("terms:page.term37")}</li>
            <li>{t("terms:page.term38")}</li>
            <li>{t("terms:page.term39")}</li>
            <li>{t("terms:page.term40")}</li>
          </ol>
        </List>
      </Container>
    </>
  );
};

export default Terms;
