import styled from "styled-components";
import nowe1 from "../../assets/images/nowe1.jpg";
import mapa from "../../assets/images/mapa.png";

export const Wrapper = styled.main`
  overflow-x: hidden;
  background-color: #fff;
`;

export const Header = styled.div`
  min-height: 100vh;
  width: 100%;
  background-image: url(${nowe1});
  background-position: center;
  position: relative;
  background-size: cover;
`;

export const TextBox = styled.div`
  width: 90%;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  & h3 {
    font-size: 70px;
    color: #e3e3e3;
    letter-spacing: -3px;

    @media (max-width: 768px) {
      font-size: 40px;
    }
  }

  & p {
    margin: 10px 0 40px;
    font-size: 20px;
    color: #e3e3e3;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  & a {
    display: inline-block;
    text-decoration: none;
    background: #eab42d;
    color: #fff;
    border: 1px solid #eab42d;
    padding: 12px 34px;
    font-size: 13px;
    position: relative;
    cursor: pointer;

    @media (max-width: 768px) {
      padding: 10px 28px;
      font-size: 12px;
    }
  }

  & a:hover {
    border: 1px solid #fff;
    color: #fff;
    background: transparent;
    cursor: pointer;
    transition: all 1s ease-out;
  }
`;

export const Offer = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 85px;

  @media (max-width: 768px) {
    padding: 40px;
  }

  & h1 {
    color: #eab42d;
    font-size: 1.9rem;
    font-weight: lighter;
    line-height: 65px;
    text-transform: uppercase;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 40px;
    }
  }

  & p {
    color: #777;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
    font-size: 19px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
`;

export const Row = styled.div`
  margin-top: 5%;
  justify-content: space-between;
  display: flex;

  @media (max-width: 1024px) {
    flex-direction: column;

    & > * {
      margin-top: 1rem;
    }
  }
`;

export const OfferCol = styled.div`
  flex-basis: 31%;
  background-color: rgb(227, 227, 227);
  margin-bottom: 0%;
  padding: 20px 12px;
  box-sizing: border-box;

  & p {
    color: #777;
    font-size: 19px;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  & h3 {
    text-align: center;
    font-weight: 600;
    margin: 10px 0;
    text-transform: uppercase;
    color: #777;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }
  }
`;

export const Our = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 100px;

  @media (max-width: 768px) {
    padding-top: 50px;
  }

  & > p {
    color: #777;
    font-weight: 300;
    line-height: 22px;
    padding: 10px;
    font-size: 19px;

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  & > h1 {
    color: #eab42d;
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 1.9rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }
`;

export const OurCol = styled.div`
  flex-basis: 31%;
  border-radius: 10px;
  text-align: center;

  & img {
    width: 100%;
    height: 70%;
    object-fit: cover;

    @media (max-width: 1024px) {
      max-height: 300px;
    }
  }

  & p {
    padding: 0;
    /* font-size: 19px; */
  }

  & h3 {
    margin-top: 16px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 1rem;
    color: #eab42d;
  }
`;

export const Call = styled.div`
  background-image: url(${mapa});
  background-position: center;
  background-size: cover;
  text-align: center;
  padding: 100px 0;
  height: 400px;

  @media (max-width: 768px) {
    padding: 50px 0;
    height: 300px;
  }

  & h1 {
    color: #fff;
    margin-bottom: 40px;
    padding: 0;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }
  }

  & a {
    flex: 0;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #eab42d;
    padding: 12px 60px;
    font-size: 13px;
    background: #eab42d;
    position: relative;
    left: -2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    height: fit-content;
    margin: 2rem;

    @media (max-width: 768px) {
      padding: 10px 40px;
      font-size: 12px;
      margin: 1rem;
      left: 0;
    }
  }

  & a:hover {
    color: #eab42d;
    border: 1px solid #eab42d;
    background: transparent;
    cursor: pointer;
  }

  & div {
    position: absolute;
    bottom: calc(-360dvh + 500px);
    width: 100dvw;
    height: 200px;
    background: linear-gradient(180deg, rgba(38, 37, 38, 0) 0%, #262626 80%);
  }
`;
