import styled from "styled-components";
import img23 from "../../assets/images/img23.jpg";

export const SubHeader2 = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)), url(${img23});
  background-position: center;
  position: relative;
  background-size: cover;
  text-align: center;
  color: #fff;

  & h1 {
    line-height: 9;
    color: #fff;
    font-weight: lighter;
  }
`;

export const ContactUs = styled.div`
  width: 80%;
  margin: auto;
`;

export const Row = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;
`;

export const ContactCol = styled.div`
  flex-basis: 48%;
  margin-bottom: 30px;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & textarea {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & button {
    flex: 0;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #eab42d;
    padding: 12px 60px;
    font-size: 13px;
    background: #eab42d;
    position: relative;
    left: -2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    height: fit-content;
    margin: 2rem;
  }

  & button:hover {
    color: #eab42d;
    border: 1px solid #eab42d;
    background: transparent;
    cursor: pointer;
  }
`;

export const Coc = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & input {
    width: 100%;
    padding: 15px;
    margin-bottom: 17px;
    outline: none;
    border: 1px solid #ccc;
  }

  & p {
    padding: 0;
  }

  & h5 {
    font-size: 20px;
    margin-bottom: 5px;
    color: #555;
    font-weight: 400;
  }
`;

export const Location = styled.div`
  width: 100vw;
  margin: auto;
  padding: 80px 0;

  & iframe {
    width: 100%;
    height: 500px;
  }
`;
