import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./components/Navbar";
import React from "react";
import Home from "./views/Home";
import Footer from "./components/Footer";
import Contact from "./views/Contact";
import About from "./views/About";
import Team from "./views/Team";
import Terms from "./views/Terms";

export default function App() {
  return (
    <div className="App">
      <Navbar />
      <Router>
        <Routes>
          <Route path="" element={<Home />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/team" element={<Team />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}
