import styled from "styled-components";
import img23 from "../../assets/images/nowe1.jpg";

export const Header = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(${img23});
  background-position: center;
  position: relative;
  background-size: cover;
  text-align: center;
  color: #fff;

  & h1 {
    line-height: 10;
    color: #fff;
    font-weight: lighter;

    & b {
      color: #fbac03;
    }
  }

  & p {
    color: red;
  }
`;

export const Container = styled.div`
  margin: 30px;

  & p {
    text-align: center;
  }

  .header {
    margin: 30px;
  }
  & h2 {
    text-align: center;
    color: red;
    font-size: 1rem;
  }

  & h3 {
    text-align: center;
  }

  & hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const List = styled.div`
  margin: 30px;

  & li {
    padding: 5px;
    text-align: justify;
  }
`;
