export const MenuItems = [
  {
    dictKey: "home:nav.home",
    url: "/",
    cName: "nav-links",
  },
  {
    dictKey: "home:nav.aboutUs",
    url: "/about",
    cName: "nav-links",
  },
  {
    dictKey: "home:nav.contact",
    url: "/contact",
    cName: "nav-links",
  },
  {
    dictKey: "home:nav.terms",
    url: "/terms",
    cName: "nav-links",
  },
  {
    dictKey: "home:nav.team",
    url: "/team",
    cName: "nav-links",
  },
];
