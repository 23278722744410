import React from "react";
import { SubHeader, AboutUs, Row, AboutCol, Click } from "./aboutElements";
import logo from "../../assets/images/about4.jpg";
import about from "../../assets/images/about5.jpg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

function Photo() {
  return <img src={logo} />;
}

const MotionDiv = ({ children, className, initial, animate, transition }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start(animate);
    }
  }, [controls, animate, inView]);

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={controls}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const About = () => {
  const { t } = useTranslation();
  return (
    <>
      <SubHeader
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{t("about:Page.Header")}</h1>
      </SubHeader>

      <AboutUs
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Row>
          <AboutCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h1>{t("about:Page.Title")}</h1>
            <p>{t("about:Page.ParagrafOne")}</p>
          </AboutCol>
          <AboutCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Photo />
          </AboutCol>
        </Row>

        <Row>
          <AboutCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={about} />
          </AboutCol>
          <AboutCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <p>{t("about:Page.ParagrafTwo")}</p>
            <Click href="/team"> {t("about:Page.Discover")}</Click>
          </AboutCol>
        </Row>
      </AboutUs>
    </>
  );
};

export default About;
