import React from "react";
import { Card, IconText, PhoneNumber } from "./teamElements";
import { AiOutlinePhone, AiOutlineMail } from "react-icons/ai";

const MateCard = ({ userInfo }) => {
  return (
    <Card>
      <img
        src={`https://onitsoft-static-apps.s3.eu-central-1.amazonaws.com/magda-trans/employees-images/${userInfo.avatar_link}`}
        alt={`${userInfo.first_name} ${userInfo.last_name}`}
      />
      <h3>
        {userInfo.first_name} {userInfo.last_name}
      </h3>
      <p>{userInfo.title}</p>
      <IconText>
        <AiOutlinePhone />
        <p>
          {userInfo.phone ? (
            <a
              href={`tel:${userInfo.phone.replace(/\s+/g, "")}`}
              className="hover-link"
            >
              {userInfo.phone}
            </a>
          ) : (
            "Phone number not available"
          )}
        </p>
      </IconText>
      <IconText>
        <AiOutlineMail />
        <p>
          {userInfo.email ? (
            <a href={`mailto:${userInfo.email}`} className="hover-link">
              {userInfo.email}
            </a>
          ) : (
            "Email not available"
          )}
        </p>
      </IconText>
    </Card>
  );
};

export default MateCard;
