import styled from "styled-components";
import about1 from "../../assets/images/about1.jpg";

export const SubHeader = styled.div`
  min-height: 50vh;
  width: 100%;
  background-image: linear-gradient(rgba(4, 9, 30, 0.7), rgba(4, 9, 30, 0.7)),
    url(${about1});
  background-position: center;
  position: relative;
  background-size: cover;
  text-align: center;

  & h1 {
    line-height: 9;
    color: #fff;
    font-weight: lighter;
  }
`;

export const AboutUs = styled.div`
  width: auto;
  margin: auto;
  padding-top: -30px;
  padding-bottom: 50px;
`;

export const Row = styled.div`
  margin-top: 5%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const AboutCol = styled.div`
  flex-basis: 90%;
  padding: 30px 70px;
  margin: 10px;

  & img {
    width: 100%;
  }

  & h1 {
    padding-top: 0;
    color: #eab42d;
    text-transform: uppercase;
    font-weight: lighter;
  }

  & p {
    padding: 15px 0 25px;
    line-height: 25px;
    font-size: medium;
    text-align: justify;
  }

  & img {
    width: auto;
    margin-top: 20px;
    height: 400px;
  }
`;

export const Click = styled.a`
    flex: 0;
    display: inline-block;
    text-decoration: none;
    color: #fff;
    border: 1px solid #eab42d;
    padding: 12px 60px;
    font-size: 13px;
    background: #eab42d;
    position: relative;
    left: -2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    height: fit-content;
    margin: 2rem;

  & a:hover {
    color: #eab42d;
    border: 1px solid #eab42d;
    background: transparent;
    cursor: pointer;
  }
`;
