import React, { useState } from "react";
import {
  SubHeader2,
  ContactCol,
  Row,
  Coc,
  ContactUs,
  Location,
} from "./contactElements";
import { useForm, Controller } from "react-hook-form";
import API from "../../api";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const MotionDiv = ({ children, className, initial, animate, transition }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start(animate);
    }
  }, [controls, animate, inView]);

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={controls}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const ContactForm = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
      category: "",
    },
  });

  const onSubmit = (data) => {
    data.category = "contact request";
    API.sendContactRequest(data).then((res) => console.log(res));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name={"name"}
        control={control}
        render={({ field, onChange }) => (
          <input
            type="text"
            name="name"
            placeholder={t("contact:page.namePlaceholder")}
            {...register("name")}
          />
        )}
      />
      <Controller
        name={"email"}
        control={control}
        render={({ field, onChange }) => (
          <input
            type="email"
            name="email"
            placeholder={t("contact:page.emailPlaceholder")}
            {...register("email")}
          />
        )}
      />
      <Controller
        name={"phone"}
        control={control}
        render={({ field, onChange }) => (
          <input
            type="phone"
            name="phone"
            placeholder={t("contact:page.phonePlaceholder")}
            {...register("phone")}
          />
        )}
      />
      <Controller
        name={"subject"}
        control={control}
        render={({ field, onChange }) => (
          <input
            type="text"
            name="subject"
            placeholder={t("contact:page.subjectPlaceholder")}
            {...register("subject")}
          />
        )}
      />
      <Controller
        name={"message"}
        control={control}
        render={({ field, onChange }) => (
          <textarea
            rows="8"
            name="message"
            placeholder={t("contact:page.messagePlaceholder")}
            {...register("message")}
          />
        )}
      />
      <button type="submit">{t("contact:page.submitButton")}</button>
    </form>
  );
};

const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <SubHeader2
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{t("contact:page.header")}</h1>
      </SubHeader2>

      <ContactUs
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Row>
          <ContactCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Coc>
              <span>
                <h5>+48 12 350 27 23</h5>
                <p>{t("contact:page.phoneHours")}</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>Cystersów 13A/1</h5>
                <p>{t("contact:page.city")}</p>
              </span>
            </Coc>
            <Coc>
              <span>
                <h5>biuro@magda-trans.pl</h5>
                <p>{t("contact:page.emailDescription")}</p>
              </span>
            </Coc>
          </ContactCol>
          <ContactCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <ContactForm />
          </ContactCol>
        </Row>
      </ContactUs>

      <Location
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.145191338081!2d19.967525115717493!3d50.064842479424016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b25b37767d9%3A0x8e1fb16425e1bfef!2sMagda-Trans%20Sp.z.o.o.!5e0!3m2!1spl!2spl!4v1682526732857!5m2!1spl!2spl"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Location>
    </>
  );
};

export default Contact;
