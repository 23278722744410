import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: ${(props) =>
    !props.isScrolled ? "none" : "0 5px 15px 0 rgba(0, 5, 5, .10)"};
  background-color: ${(props) =>
    !props.isScrolled ? "transparent" : "rgba(38, 38, 38, .6)"};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
  transition: all 0.2s linear;
`;

export const Logo = styled.a`
  color: rgb(69, 69, 69);
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #fbac03;
  }
`;

export const NavMenu = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;

  & li {
    display: inline-block;
    margin: 0 10px;
    position: relative;
  }

  & a {
    display: block;
    padding: 10px 20px;
    margin: 30px 0;
    text-decoration: none;
    cursor: pointer;
    font-size: 1rem;
    color: #fff;
    transition: all 0.2s linear;
    letter-spacing: 1px;

    &:hover {
      color: #fbac03;
    }

    &::after {
      content: "";
      display: block;
      width: 0;
      height: 2px;
      background: #fbac03;
      transition: width 0.2s;
    }

    &:hover::after {
      width: 100%;
    }

    &.active::after {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const BurgerMenu = styled.div`
  display: none;
  width: 50px;
  height: 32px;
  cursor: pointer;

  & > span {
    display: block;
    width: 100%;
    height: 2px;
    position: relative;
    background-color: #000;
    transition: all 0.3s linear;

    &:first-of-type {
      transform: ${(props) =>
        props.isOpen ? "translateY(14px) rotate(45deg)" : "rotate(0deg)"};
    }

    &:nth-child(2) {
      top: 13px;
      opacity: ${(props) => (props.isOpen ? 0 : 1)};
    }

    &:last-of-type {
      top: 26px;
      transform: ${(props) =>
        props.isOpen ? "translateY(-14px) rotate(-45deg)" : "rotate(0deg)"};
    }
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
`;

export const SignButton = styled.li`
  display: inline-block;
  border-radius: 0.3rem;
  font-size: auto;
  font-weight: 550;
  border: none;
  cursor: pointer;

  & > a:hover {
    background-color: #fbac03;
    transition: background-color 0.2s ease-in-out;
    color: #fff;
  }
`;

export const Img = styled.img`
  padding: 10px;
  display: flex;
  align-items: center;
  width: auto;
  height: 70px;
`;

export const Drawer = styled.div`
  height: calc(100vh - 100px);
  background-color: #aaa;
  width: 100vw;
  position: absolute;
  top: ${(props) => (props.isOpen ? "100px" : "-100vh")};
  left: 0;
  transition: all 0.3s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > a {
    color: #fff;
    font-size: 1.5rem;
    text-decoration: none;
    margin-bottom: 1rem;
  }

  & ul {
    list-style-type: none;
    padding: 0;

    & li {
      margin: 1rem 0;

      & a {
        color: #fff;
        font-size: 1.2rem;
        text-decoration: none;

        &:hover {
          color: #fbac03;
        }
      }
    }
  }
`;

export const Translate = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: auto;

  & div {
    width: fit-content;
  }

  & button {
    padding: 0.5rem 1rem;
    margin: 0 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;

    &:hover {
      background-color: #d99a2b;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const FlagIcon = styled.img`
  width: 24px;
  height: 16px;
  margin-right: 8px;
`;

export const LanguageSelector = styled.div`
  position: relative;
  display: inline-block;

  & .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    & a {
      color: black;
      padding: 12px 16px;
      text-decoration: none;
      display: block;

      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  &:hover .dropdown-content {
    display: block;
  }

  & .dropbtn {
    background-color: transparent;
    color: white;
    padding: 10px 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;

    &:hover {
      background-color: #d99a2b;
    }
  }
`;
