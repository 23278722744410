import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { MenuItems } from "./MenuItems";
import {
  Nav,
  Logo,
  NavMenu,
  SignButton,
  Img,
  BurgerMenu,
  Drawer,
  Translate,
  FlagIcon,
  LanguageSelector,
} from "./navbarElements";

function Translation() {
  const { t, i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "pl");

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    Cookies.set("i18next", language, { expires: 365 });
    setCurrentLanguage(language);
  };

  useEffect(() => {
    const savedLanguage = Cookies.get("i18next");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
      setCurrentLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <LanguageSelector>
      <button className="dropbtn">
        <FlagIcon
          src={
            currentLanguage === "pl"
              ? "https://static.vecteezy.com/system/resources/previews/016/328/554/original/poland-flat-rounded-flag-icon-with-transparent-background-free-png.png"
              : "https://upload.wikimedia.org/wikipedia/commons/1/13/United-kingdom_flag_icon_round.svg"
          }
          alt={currentLanguage === "pl" ? "PL" : "EN"}
        />{" "}
        {currentLanguage === "pl" ? "PL" : "EN"}
      </button>
      <div className="dropdown-content">
        <a onClick={() => changeLanguage("en")}>
          <FlagIcon
            src="https://upload.wikimedia.org/wikipedia/commons/1/13/United-kingdom_flag_icon_round.svg"
            alt="EN"
          />{" "}
          English
        </a>
        <a onClick={() => changeLanguage("pl")}>
          <FlagIcon
            src="https://static.vecteezy.com/system/resources/previews/016/328/554/original/poland-flat-rounded-flag-icon-with-transparent-background-free-png.png"
            alt="PL"
          />{" "}
          Polski
        </a>
      </div>
    </LanguageSelector>
  );
}

const Navbar = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleItemClick = (index) => {
    setActiveItem(index);
    setIsDrawerOpen(false); // Zamknij Drawer po kliknięciu linku
  };

  return (
    <Nav isScrolled={isScrolled}>
      <Logo href={"/"}>
        <Img src="logomagda.png" />
      </Logo>
      <NavMenu isScrolled={isScrolled}>
        {MenuItems.map((item, index) => (
          <li key={index}>
            <a
              href={item.url}
              className={activeItem === index ? "active" : ""}
              onClick={() => handleItemClick(index)}
            >
              {t(item.dictKey)}
            </a>
          </li>
        ))}
        <SignButton>
          <a
            href="https://www.mobi.magda-trans.pl/login"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("navbar:page.rej")}
          </a>
        </SignButton>
      </NavMenu>
      <Translation />
      <BurgerMenu
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        isOpen={isDrawerOpen}
      >
        <span />
        <span />
        <span />
      </BurgerMenu>
      <Drawer isOpen={isDrawerOpen}>
        {MenuItems.map((item, index) => (
          <a
            href={item.url}
            key={index}
            className={activeItem === index ? "active" : ""}
            onClick={() => handleItemClick(index)}
          >
            {t(item.dictKey)}
          </a>
        ))}
      </Drawer>
    </Nav>
  );
};

export default Navbar;
