import axios from "axios";

class API {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.instance = axios.create({
      baseURL: baseURL,
    });
  }

  sendContactRequest = async (contactData) => {
    return await this.instance.post(`/contact/magda-trans`, contactData);
  };
}

const api = new API("https://api.onitsoft.com");

export default api;
