import React, { useRef } from "react";
import {
  Header,
  TextBox,
  Offer,
  Row,
  OfferCol,
  Our,
  OurCol,
  Call,
  Wrapper,
} from "./homeElements";
import { BsChevronCompactRight, BsChevronCompactLeft } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import elfa from "../../assets/logos/elfa.png";
import logo1 from "../../assets/logos/logo1.png";
import logo5 from "../../assets/logos/logo5.jpg";
import logo7 from "../../assets/logos/logo7.png";
import logo8 from "../../assets/logos/logo8.png";
import logo9 from "../../assets/logos/logo9.png";
import logo10 from "../../assets/logos/logo10.jpg";
import logo11 from "../../assets/logos/logo11.jpg";
import logo20 from "../../assets/logos/logo20.png";
import logo21 from "../../assets/logos/logo21.jpg";
import Slider from "./Slider";
import img17 from "../../assets/images/img17.jpg";
import kontener from "../../assets/images/kontener.jpg";
import teamwork from "../../assets/images/teamwork.jpg";
import { t } from "i18next";

const LogoCarousel = ({ logos }) => {
  const carouselRef = useRef(null);

  const scroll = (direction) => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: direction * 200, // Scroll by 200 pixels left or right
        behavior: "smooth",
      });
    }
  };

  return (
    <motion.div
      className="carousel-container"
      style={{ margin: "3rem 10rem" }}
      initial={{ opacity: 0, x: -50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h1
        style={{
          textAlign: "center",
          marginBottom: "4rem",
          fontWeight: "lighter",
          fontSize: "1.9rem",
          fontFamily: "arial",
          color: "#eab42d",
        }}
      >
        {t("home:page.work_with_us")}
      </h1>
      <div style={{ position: "relative" }}>
        <button
          className="arrow-button left"
          onClick={() => scroll(-1)}
          style={{
            position: "absolute",
            left: "-55px",
            width: "20px",
            height: "100px",
            background: "none",
            outline: "none",
            border: "none",
          }}
        >
          <BsChevronCompactLeft style={{ fontSize: "4rem" }} />
        </button>
        <div
          className="carousel"
          ref={carouselRef}
          style={{
            overflowX: "scroll",
            height: "150px",
            margin: "0 2rem",
            display: "flex",
          }}
        >
          {logos.map((logo, index) => (
            <motion.img
              key={index}
              src={logo}
              alt="logo"
              className="logo"
              style={{ width: "auto", height: "100px", margin: "0 2rem" }}
              whileHover={{ scale: 1.1 }}
            />
          ))}
        </div>
        <button
          className="arrow-button right"
          onClick={() => scroll(1)}
          style={{
            position: "absolute",
            top: 0,
            right: "-15px",
            width: "20px",
            height: "100px",
            background: "none",
            outline: "none",
            border: "none",
          }}
        >
          <BsChevronCompactRight style={{ fontSize: "4rem" }} />
        </button>
      </div>
    </motion.div>
  );
};

const MotionDiv = ({ children, className, initial, animate, transition }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start(animate);
    }
  }, [controls, animate, inView]);

  return (
    <motion.div
      ref={ref}
      initial={initial}
      animate={controls}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const Home = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Header
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <Slider />
        <TextBox
          as={MotionDiv}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h3>{t("home:page.banner_title")}</h3>
          <p>{t("home:page.banner_text")}</p>
          <a href="/about">{t("home:page.more_info")}</a>
        </TextBox>
      </Header>
      <Offer
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{t("home:page.offer_title")}</h1>
        <p>{t("home:page.offer_text")}</p>
        <Row>
          <OfferCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h3>{t("home:page.freight")}</h3>
            <p>{t("home:page.freight_text")}</p>
          </OfferCol>

          <OfferCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h3>{t("home:page.transport")}</h3>
            <p>{t("home:page.transport_text")}</p>
          </OfferCol>

          <OfferCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <h3>{t("home:page.logistics")}</h3>
            <p>{t("home:page.logistics_text")}</p>
          </OfferCol>
        </Row>
      </Offer>

      <Our
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{t("home:page.our_services_title")}</h1>
        <p>{t("home:page.our_services_text")}</p>

        <Row>
          <OurCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={img17} />
            <h3>{t("home:page.custom_transport")}</h3>
            <p>{t("home:page.custom_transport_text")}</p>
          </OurCol>
          <OurCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={teamwork} />
            <h3>{t("home:page.trustworthy_partnership")}</h3>
            <p>{t("home:page.trustworthy_partnership_text")}</p>
          </OurCol>
          <OurCol
            as={MotionDiv}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <img src={kontener} />
            <h3>{t("home:page.excellent_planning")}</h3>
            <p>{t("home:page.excellent_planning_text")}</p>
          </OurCol>
        </Row>
      </Our>

      <LogoCarousel
        logos={[
          logo1,
          logo5,
          logo7,
          logo8,
          logo21,
          logo20,
          logo9,
          logo10,
          elfa,
          logo11,
        ]}
      />

      <Call
        as={MotionDiv}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{t("home:page.contact_title")}</h1>
        <a href="/contact">{t("home:page.contact")}</a>
      </Call>
    </Wrapper>
  );
};

export default Home;
