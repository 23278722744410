import { use } from "i18next";
import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//PL
import aboutPL from "./assets/dictionaries/about/pl.json";
import contactPL from "./assets/dictionaries/contact/pl.json";
import homePL from "./assets/dictionaries/home/pl.json";
import footerPL from "./assets/dictionaries/footer/pl.json";
import termsPL from "./assets/dictionaries/terms/pl.json";
import navbarPL from "./assets/dictionaries/navbar/pl.json";

//EN
import aboutEN from "./assets/dictionaries/about/en.json";
import contactEN from "./assets/dictionaries/contact/en.json";
import homeEN from "./assets/dictionaries/home/en.json";
import footerEN from "./assets/dictionaries/footer/en.json";
import termsEN from "./assets/dictionaries/terms/en.json";
import navbarEN from "./assets/dictionaries/navbar/en.json";

const languageCookie = Cookies.get("lang");
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "pl",
    lng: languageCookie,
    ns: ["about", "contact", "home", "footer", "terms", "navbar"],
    supportedLngs: ["en", "pl"],
    resources: {
      pl: {
        about: { ...aboutPL },
        contact: {...contactPL},
        home: { ...homePL},
        footer: { ...footerPL},
        terms: { ...termsPL},
        navbar: { ...navbarPL}
      },

      en: {
        about: { ...aboutEN },
        contact: { ...contactEN},
        home: { ...homeEN},
        footer: { ...footerEN},
        terms: { ...termsEN},
        navbar: { ...navbarEN}
      },
    },
    detection: {
      order: ['cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      cookieMinutes: 60 * 24 * 365,
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
    },
    interpolation: {
      escapeValue: false,
    },
  });
